<template>
  <div :class="$style.wrapper">
    <b-container :class="$style.inner">
      <div :class="[$style.contentWrapper, 'dflex']">
        <div :class="[$style.presentWrap, 'dflex']">
          <div :class="$style.logo">
            <img src="../assets/login/logo_ru.png" alt="логотип" />
          </div>
          <div :class="[$style.infoWrap, 'dflex', 'md-align-end']">
            <div :class="$style.flag"></div>
            <div :class="$style.titleInfo">ЛИЧНЫЙ КАБИНЕТ</div>
            <div :class="[$style.phoneWrap, 'dflex', 'sm-align-end']">
              <PhoneSvg :class="$style.phoneIcon"></PhoneSvg>
              <span :class="$style.phoneNumber">8 (495) 44-96-000</span>
            </div>
            <div>
              <a href="https://www.onlinelogic.ru">www.onlinelogic.ru</a>
            </div>
            <div :class="[$style.socialWrap, 'dflex']">
              <a href="https://www.facebook.com/OnLoDialog" :class="$style.icon"></a>
              <a href="https://www.instagram.com/logic.online/" :class="$style.icon"></a>
              <a
                href="https://www.youtube.com/channel/UCjQGOpFgeO5a8408a_Z4o2Q/featured"
                :class="$style.icon"
              ></a>
              <a href="https://tglink.ru/stranzit_help_bot" :class="$style.icon"></a>
            </div>
          </div>
          <!-- Сообщения об ошибке -->
          <login-error
            v-if="isError"
            :errorMessage="errorMessage"
            :classList="classList"
            :messageList="messages"
          >
          </login-error>
        </div>
        <!-- Форма авторизации -->
        <div :class="[$style.presentWrap, 'dflex']">
          <h2 :class="$style.title">АВТОРИЗАЦИЯ</h2>
          <b-form @submit.prevent.enter="signIn" :class="$style.formWrap">
            <b-form-group>
              <b-input
                v-model="login"
                :class="$style.inputForm"
                autocomplete="username"
                placeholder="Логин"
              ></b-input>
              <b-input
                v-model="password"
                :class="$style.inputForm"
                type="password"
                autocomplete="current-password"
                placeholder="Пароль"
              ></b-input>
            </b-form-group>
            <div :class="[$style.buttonsWrap, 'dflex']">
              <BaseButton @click.stop.prevent="signIn" :class="$style.loginBtn">
                <template #text>ВХОД</template>
              </BaseButton>
              <a href="#" @click.prevent :class="$style.forgetPass" v-b-modal.modal-foget-password>
                Забыли пароль?
              </a>
            </div>
          </b-form>
          <div :class="[$style.footer, 'dflex']">
            <a href="#" @click.prevent="register" :class="$style.regLink"> Зарегистрироваться </a>
            <div :class="$style.termsWrap">
              <span>Нажимая кнопку "Вход", вы принимаете условия</span>
              <br />
              <a href="./docs/Положение_ОнлайнЛоджик_ноябрь_2021.pdf" :class="$style.terms">
                пользовательского соглашения
              </a>
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <b-modal id="modal-foget-password" title="Внимание" ok-only>
      <p class="my-4">
        Для восстановления доступа обратитесь в отдел обслуживания по почте sales@stranzit.ru, или
        позвоните по номеру 8 (3952) 70-70-55
      </p>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { catchFunction } from '@/additional_helpers/helpers';
import { signInUser } from '@/api/common';
import URL from '@/basUrl';
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import PhoneSvg from '@/components/shared/icons_and_images/PhoneSvg.vue';
import img from '../assets/logic_logo_черный_eng.svg';

export default {
  data() {
    return {
      img,
      login: '',
      password: '',
      remember: false,
      isError: false,
      errorMessage: '',
      messages: [],
      classList: {
        errorLogin: this.$style.errorLogin,
        errorPassword: this.$style.errorPassword,
        error: this.$style.errorWrap,
        innerClass: this.$style.errorInner
      }
    };
  },

  components: {
    BaseButton,
    PhoneSvg,
    loginError: () => import('@/components/shared/renderless_components/loginError.vue')
  },

  computed: {
    ...mapGetters({ servicesData: 'connectedServices/servicesData' }),

    messageList() {
      return this.messages;
    }
  },

  methods: {
    ...mapActions({ loginAction: 'loginStore/login' }),
    ...mapMutations({ setCurrentUserData: 'loginStore/changeUser' }),

    checkHasErrors(obj) {
      return (
        obj.status !== 200
        && obj.data.errors
        && (obj.data.errors.Login?.[0] !== 'undefined'
          || obj.data.errors.Password?.[0] !== 'undefined')
      );
    },

    createBodyRequest() {
      return JSON.stringify({
        Login: this.login,
        Password: this.password,
        Infinite: this.remember
      });
    },

    hideErrorMessage() {
      setTimeout(() => {
        this.isError = false;
      }, 5000);
    },

    async signIn() {
      const response = await signInUser(`${URL.AUTH}login`, this.createBodyRequest());

      try {
        const routePathWithQuery = this.$route.fullPath.includes('?');
        this.messages = [];

        /* Показывает ошибку в случаях, когда ответ от сервера - строка, например:
          "Указан неверный логин или пароль." */
        if (response.status !== 200 && typeof response.data === 'string') {
          this.isError = true;
          this.errorMessage = response.data;
          this.hideErrorMessage();
          return;
        }

        // Показывает ошибку в остальных случаях
        if (this.checkHasErrors(response)) {
          const {
            errors: { Login, Password }
          } = response.data;

          if (Login && !Password) {
            this.errorMessage = `${Login?.[0] ?? ''}`;
          } else if (!Login && Password) {
            this.errorMessage = `${Password?.[0] ?? ''}`;
          } else {
            this.errorMessage = `${Login?.[0] ?? ''}
            ${Password?.[0] ?? ''}`;
          }

          this.messages.push(Login?.[0], Password?.[0]);
          this.isError = true;
          this.hideErrorMessage();
          return;
        }

        // Срабатывает в случаях, когда логин прошел успешно
        if (response.status === 200) {
          this.setCurrentUserData(response.data);

          if (!routePathWithQuery) {
            this.$router.push('/');
            return;
          }

          this.$router.replace({ path: this.$route.query.returnUrl });
        }
      } catch (e) {
        catchFunction(e);
      }
    },

    register() {
      this.$router.push('/registration');
    }
  }
};
</script>

<style lang="scss" module>
.wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
}

.inner {
  height: 100%;
  color: $hoverBlack;
  position: relative;

  & > div {
    min-height: inherit;
  }
}

.contentWrapper {
  position: relative;
  justify-content: center;
}

.presentWrap {
  width: 100%;
  position: relative;
  padding-top: 4.5rem;

  &:first-child {
    padding: 1.5rem 0.875rem 1.5rem 0.875rem;
    background: url('../assets/login/background-1.png') no-repeat;
    background-size: cover;
  }

  &:nth-child(2) {
    padding: 2.5rem 2rem 0.875rem 2rem;
    flex-direction: column;
    text-align: center;
    background: $white;
    border: 1px solid $mainButton;
  }

  &:first-child:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    opacity: 0.88;
  }
}

.logo {
  width: 100%;
  position: relative;
  text-align: center;

  & > img {
    width: 15rem;
    height: 5.6rem;
  }
}

.infoWrap {
  width: 100%;
  padding-top: 2rem;
  font-size: 1rem;
  text-align: center;
  position: relative;
  flex-direction: column;
  font-weight: 600;
  z-index: 1;

  & > div {
    padding: 0.5rem 0 0 0;
  }

  & > div:nth-child(2) {
    padding: 0.5rem 0 0 0;
  }

  & > div:last-child {
    padding-top: 0.625rem;
  }
}

.flag {
  height: 4.5rem;
  background: center/contain no-repeat url('../assets/login/flag.png');
}

.titleInfo {
  font-weight: 700;
}

.phoneWrap {
  padding: 0.5rem 0 0 0;
  align-items: center;
  justify-content: center;
}

.phoneIcon {
  width: 1.35rem;
  height: 1.53rem;
  margin-right: 0.5rem;
  transform: rotate(270deg);
}

.phoneNumber {
  font-size: 1rem;
}

.socialWrap {
  width: 100%;
  justify-content: space-between;
}

.icon {
  width: 2rem;
  height: 2rem;
  display: block;
  margin-left: 1rem;

  &:first-child {
    background: no-repeat center / 190% url('../assets/icons/icons.svg#facebook');
    margin: 0;
  }
  &:last-child {
    background: no-repeat center / 260% url('../assets/icons/icons.svg#telegram');
  }
  &:nth-child(2) {
    background: no-repeat center / 270% url('../assets/icons/icons.svg#insta');
  }
  &:nth-child(3) {
    background: no-repeat center / 270% url('../assets/icons/icons.svg#youtube');
  }
}

// Форма авторизации
.formWrap {
  padding-top: 1rem;
}

.inputForm {
  height: 2.75rem;
  font-size: 1.25rem;
  color: $white;
  background: $transparentGrey;
  border-radius: 0;
  border: none;

  &:last-child {
    margin-top: 1rem;
  }

  &::placeholder {
    line-height: 1;
    color: $white;
  }
}

.buttonsWrap {
  padding-top: 1.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.loginBtn {
  width: 6.625rem;
  height: 3.125rem;
  font-size: 1.5rem;
  text-align: center;
  justify-content: center;
  background: $red;
  border-radius: 0;
}

.forgetPass {
  padding-top: 1.5rem;
  font-size: 1rem;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
}

.regLink {
  font-size: 1.5rem;
  color: #1969ae;

  &:hover {
    color: #1969ae;
  }
}

.footer {
  padding-top: 2rem;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.termsWrap {
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  // new small
  padding-top: 2rem;
}

.terms {
  color: $linkBlue;
}

// Стили для ошибки авторизации
.errorWrap,
.errorLogin,
.errorPassword {
  width: inherit;
  padding: 1rem 0;
  position: fixed;
  right: 0;
  top: 0;
  font-size: 1rem;
  text-align: center;
  background: $red;
  color: $white;
  z-index: 1;
  white-space: pre-line;
  box-shadow: 0px 0px 10px 2px $red;
}

// Медиа стили
@media (min-width: 576px) {
  .buttonsWrap {
    flex-direction: row;
    justify-content: center;
  }

  .forgetPass {
    padding-top: 0;
    padding-left: 2rem;
  }
}

@media (min-width: 768px) {
  .presentWrap {
    &:first-child {
      padding: 2.5rem 0.875rem 0.75rem 2rem;
      background: url('../assets/login/background-1.png') no-repeat;
      background-position: 42% 0;
      background-size: 101%;
    }

    &:first-child:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-height: inherit;
      background: linear-gradient(to left top, transparent 49.9%, #fff 50%) left/77.75% 126.025%
        no-repeat;
      opacity: 0.88;
      background-position: -58% 0%;
    }

    &:first-child:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 90%;
      height: 100%;
      max-height: inherit;
      background: linear-gradient(to right bottom, transparent 49.9%, #fff 50%) left/66.025%
        100.025% no-repeat;
      background-position: 101% 0%;
    }
  }

  .logo,
  .infoWrap {
    width: 50%;
  }

  .logo {
    width: 50%;
    position: relative;
    text-align: left;

    & > img {
      width: 13.75rem;
      height: 5.1333rem;
    }
  }

  .infoWrap {
    text-align: right;
    align-content: flex-end;
  }

  .flag {
    margin-left: 2rem;
  }

  .phoneWrap {
    justify-content: flex-end;
  }

  .socialWrap {
    width: auto;
  }

  .phoneIcon {
    width: 1.5rem;
    height: 1.7rem;
  }
}

@media (min-width: 992px) {
  .inner {
    min-height: auto;
  }

  .presentWrap {
    width: 100%;
    position: relative;
    padding-top: 4.5rem;

    padding: 2.5rem 0.875rem 1.75rem 2rem;

    &:first-child {
      max-width: 50%;
      background-size: cover;
    }

    &:nth-child(2) {
      max-width: 31.81%;
    }
  }

  .logo {
    & > img {
      width: 12.5625rem;
      height: 5rem;
    }
  }

  .infoWrap {
    text-align: center;

    font-size: 1.125rem;

    & > div {
      padding: 1.125rem 0 0 0;
    }
  }

  .flag {
    height: 5.5rem;
  }

  .phoneWrap {
    justify-content: center;
  }

  .buttonsWrap {
    justify-content: space-around;
  }

  .forgetPass {
    padding-left: 0;
    font-size: 0.75rem;
  }

  .errorWrap,
  .errorLogin,
  .errorPassword {
    width: 15.5rem;
    position: absolute;
  }

  .errorWrap {
    top: 23.5%;
    padding: 0.5rem;

    &:after {
      content: '';
      position: absolute;
      right: -1.875rem;
      top: 34.5%;
      border: 10px solid transparent;
      border-left: 20px solid $red;
    }
  }

  .errorLogin {
    top: 20.4%;
    padding: 0.5rem;

    &:after {
      content: '';
      position: absolute;
      right: -1.8rem;
      top: 17.5%;
      border: 14px solid transparent;
      border-left: 20px solid $red;
    }
  }

  .errorPassword {
    top: 32.68%;
    padding: 0.5rem;

    &:after {
      content: '';
      position: absolute;
      right: -1.8rem;
      top: 16.5%;
      border: 14px solid transparent;
      border-left: 20px solid $red;
    }
  }
}

@media (min-width: 1200px) {
  .presentWrap:nth-child(2) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .infoWrap {
    font-size: 1.25rem;
  }

  .infoWrap {
    & > div {
      padding: 0.25rem 0 0 0;
    }
  }

  .loginBtn {
    width: 7.625rem;
  }
}

@media (min-width: 1280px) {
  .inner {
    max-width: 1270px;
  }

  .presentWrap:first-child {
    background-position: 10% 0;
  }

  .logo {
    & > img {
      width: 14.0625rem;
      height: 5.25rem;
    }
  }

  .forgetPass {
    font-size: 1.25rem;
    padding-left: 2rem;
  }
}
</style>
