<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="100%"
    height="100%"
    viewBox="100 100 200 200"
    xml:space="preserve"
  >
    <g transform="matrix(11.11 0 0 11.11 200 200)" id="Shape">
      <path
        style="
          stroke: none;
          stroke-width: 1;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-dashoffset: 0;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          fill: rgb(154, 154, 154);
          fill-rule: evenodd;
          opacity: 1;
        "
        transform=" translate(-9, -9)"
        d="M 3.6 7.8 C 5 10.6 7.4 12.9 10.2 14.4
        L 12.4 12.2 C 12.7 11.9 13.1 11.8 13.4 12
        C 14.5 12.4 15.7 12.6 17 12.6
        C 17.6 12.6 18 13 18 13.6 L 18 17
        C 18 17.6 17.6 18 17 18 C 7.6 18 0 10.4 0 1
        C 0 0.4 0.4 0 1 0 L 4.5 0 C 5.1 0 5.5 0.4 5.5 1
        C 5.5 2.2 5.7 3.4 6.1 4.6 C 6.2 4.9 6.1 5.3 5.9 5.6
        L 3.6 7.8 L 3.6 7.8 Z"
        stroke-linecap="round"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
